import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from 'react-bootstrap';
import {
  useGetProductDetailsQuery,
  useCreateProductReviewMutation,
} from '../slices/productsApiSlice';
import Loader from '../components/Loader';
import Rating from '../components/Rating';
import Message from '../components/Message';
import Meta from '../components/Meta';
import { addToCart } from '../slices/cartSlice';

const ProductPage = () => {
  const { id: productId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const {
    data: product,
    isLoading,
    error,
    refetch,
  } = useGetProductDetailsQuery(productId);

  const { userInfo } = useSelector((state) => state.auth);

  const [createProductReview, { isLoading: isLoadingProductReview }] =
    useCreateProductReviewMutation();

  // Event handler for adding the product to the cart
  const addToCartHandler = () => {
    dispatch(addToCart({ ...product, qty }));
    navigate('/cart');
  };

  // Event handler for submitting a review
  const submitReviewHandler = async (e) => {
    e.preventDefault();

    try {
      await createProductReview({
        productId,
        rating,
        comment,
      }).unwrap();
      refetch();
      toast.success('Review submitted. Thank you!');
      setRating(0);
      setComment('');
    } catch (err) {
      toast.error(err?.data?.message || err?.error);
    }
  };

  // Helper function to render the Product section
  const renderProduct = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (error) {
      return (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      );
    }

    return (
      <>
        <Meta title={product.name} />
        <Row>
          <Col md={5}>
            <Image src={product.image} alt={product.name} fluid />
          </Col>
          <Col md={4}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h3> {product.name} </h3>
              </ListGroup.Item>
              <ListGroup.Item>
                <Rating
                  value={product.rating}
                  text={`${product.numReviews} reviews`}
                />
              </ListGroup.Item>
              <ListGroup.Item> Price: ${product.price} </ListGroup.Item>
              <ListGroup.Item>
                Description: {product.description}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={3}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col> Price: </Col>
                    <Col>
                      <strong> ${product.price} </strong>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col> Status: </Col>
                    <Col>
                      {product.countInStock > 0 ? 'In Stock' : 'Out of Stock'}
                    </Col>
                  </Row>
                </ListGroup.Item>
                {product.countInStock > 0 && (
                  <ListGroup.Item>
                    <Row>
                      <Col> Qty </Col>
                      <Col>
                        <Form.Control
                          as="select"
                          value={qty}
                          onChange={(e) => setQty(Number(e.target.value))}
                        >
                          {[...Array(product.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  <Button
                    className="btn-block bg-dark border-0 px-3 "
                    type="button"
                    disabled={product.countInStock === 0}
                    onClick={addToCartHandler}
                  >
                    Add to Cart
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  // Helper function to render the Product Review section
  const renderProductReview = () => {
    if (isLoading || isLoadingProductReview) {
      return <Loader />;
    }

    if (error) {
      return (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      );
    }

    return (
      <Row className="review mt-4">
        <Col md={6}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h3>Reviews</h3>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}
            </ListGroup.Item>
            {product.reviews.map((review) => (
              <ListGroup.Item key={review._id}>
                <strong> {review.name} </strong>
                <Rating value={review.rating} />
                <p> {review.createdAt.substring(0, 10)} </p>
                <p> {review.comment} </p>
              </ListGroup.Item>
            ))}
            <ListGroup.Item>
              <h3>Write a Product Review</h3>
              {userInfo ? (
                <Form onSubmit={submitReviewHandler}>
                  <Form.Group controlId="rating">
                    <Form.Label>Rating</Form.Label>
                    <Form.Control
                      as="select"
                      value={rating}
                      onChange={(e) => setRating(Number(e.target.value))}
                    >
                      <option value="">Select...</option>
                      <option value="5">5 - Excellent</option>
                      <option value="4">4 - Very Good</option>
                      <option value="3">3 - Good</option>
                      <option value="2">2 - Fair</option>
                      <option value="1">1 - Poor</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="comment" className="my-2">
                    <Form.Label>Comment</Form.Label>
                    <Form.Control
                      as="textarea"
                      row="3"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    disabled={isLoadingProductReview}
                    type="submit"
                    variant="primary"
                  >
                    Submit
                  </Button>
                </Form>
              ) : (
                <Message>
                  Please <Link to="/login">sign in</Link> to write a review
                </Message>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Button
        type="button"
        className="btn-block bg-dark border-0 mb-4 px-3"
        onClick={() => navigate('/')}
      >
        Back To Shopping
      </Button>
      {renderProduct()}
      {renderProductReview()}
    </>
  );
};

export default ProductPage;
